<script setup>
import { onMounted, ref, defineProps } from 'vue'
import dayjs from 'dayjs'
import staffTrainingService from '@/services/StaffTrainingService.js'
import { apiUrl } from '@/vars.js'

const courses = ref([])
const activeCourses = ref([])
const selectedCourse = ref(0)
const selectedDate = ref(dayjs().format('YYYY-MM-DD'))
const selectedFile = ref(null)

const props = defineProps({
  staff: { type: Object, required: true },
})

onMounted(async () => {
  try {
    // Load staff's existing trainings
    const data = await staffTrainingService.fetchTrainings(props.staff.id)
    activeCourses.value = data.map(training => ({
      id: training.id, // Use the training record ID
      courseId: training.course.id, // Related course ID
      name: training.course.name,
      attended: training.attended,
      evidence: training.file?.local || null,
      file_id: training.file?.id || null,
    }))


    // Fetch available courses
    courses.value = await staffTrainingService.fetchAvailableCourses()
    selectedCourse.value = courses.value.length ? courses.value[0].id : 0
  } catch (error) {
    console.error(error)
  }
})

const add = async () => {
  if (!selectedCourse.value || !selectedDate.value) {
    return alert('Course and completion date must be complete')
  }

  const course = courses.value.find(c => c.id === selectedCourse.value)
  console.log(selectedCourse.value)
  console.log(course)
  if (!course) {
    return alert('Invalid course selected')
  }

  try {
    const training = await staffTrainingService.addTraining(
      props.staff.id,
      selectedCourse.value,
      selectedDate.value,
      selectedFile.value || null
    )

    // Add to active courses
    const selectedCourseData = courses.value.find(course => course.id === selectedCourse.value);

    activeCourses.value.push({
      id: training.id, // Use the training record ID
      courseId: training.course_id,
      file_id: training.file_id,
      name: selectedCourseData.name, // Use the course name based on ID
      attended: training.attended,
      evidence: training.file?.local || null,
    });
  } catch (error) {
    console.error(error)
  }

  // Reset form
  selectedDate.value = dayjs().format('YYYY-MM-DD')
  selectedCourse.value = courses.value[0]?.id || 0
  selectedFile.value = null
}

const del = async course => {
  try {
    // Pass the training ID to the delete endpoint
    await staffTrainingService.deleteTraining(props.staff.id, course.id)
    activeCourses.value = activeCourses.value.filter(c => c.id !== course.id)
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
      <span>Course Name</span>
      <span>Attended Date</span>
      <span>Evidence</span>
      <span></span>

      <select class="w-full" v-model="selectedCourse">
        <option v-for="course in courses" :key="course.id" :value="course.id">
          {{ course.name }}
        </option>
      </select>
      <input type="date" v-model="selectedDate" class="w-full" />
      <input type="file" @change="e => (selectedFile = e.target.files[0])" />
      <div class="text-right">
        <button class="btn w-full md:w-auto" @click.prevent="add">Add</button>
      </div>

      <template v-for="course in activeCourses" :key="course.id">
        <span>{{ course.name }}</span>
        <span>{{ new Date(course.attended).toLocaleDateString('en-GB') }}</span>
        <span>
          <a v-if="course.file_id" :href="`${apiUrl}/files/${course.file_id}`" target="_blank">
            View Evidence
          </a>
          <span v-else>No Evidence</span>
        </span>
        <div class="whitespace-nowrap md:text-right">
          <DeleteButton :disableTitle="true" @delete="() => del(course)" />
        </div>
      </template>
    </div>
  </div>
</template>
