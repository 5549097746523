import apiClient from '@/apiClient.js'

const fetchAvailableCourses = async () => {
  const response = await apiClient.get('/api/training-courses')
  return response.data
}

const fetchTrainings = async staffId => {
  const response = await apiClient.get(`/api/staff/${staffId}/training`)
  return response.data
}

const addTraining = async (staffId, courseId, attended, file = null) => {
  const formData = new FormData()
  formData.append('course_id', courseId)
  formData.append('attended', attended)

  if (file) {
    formData.append('file', file)
  }

  const response = await apiClient.post(`/api/staff/${staffId}/training`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response.data
}

const deleteTraining = async (staffId, trainingId) => {
  await apiClient.delete(`/api/staff/${staffId}/training/${trainingId}`)
}

export default {
  fetchAvailableCourses,
  fetchTrainings,
  addTraining,
  deleteTraining,
}

