<script setup>
import { ref, onMounted } from "vue";
import apiClient from "@/apiClient.js";
import * as Icon from "@heroicons/vue/24/solid";

var apps = ref([]);
apiClient
  .get("/api/user/profile/apps")
  .then((r) => {
    apps.value = r.data;
    apps.value.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  })
  .catch(() => { });

function getIconComponent(name) {
  const iconMap = {
    Activities: {
      icon: Icon.CircleStackIcon,
      classes: "inline h-6 w-6 text-teal-400",
    },
    Calendar: {
      icon: Icon.CalendarDaysIcon,
      classes: "inline h-6 w-6 text-blue-400",
    },
    Accommodation: {
      icon: Icon.BuildingOfficeIcon,
      classes: "inline h-6 w-6 text-purple-600",
    },
    Customers: {
      icon: Icon.UsersIcon,
      classes: "inline h-6 w-6 text-yellow-500",
    },
    "Bank Transactions": {
      icon: Icon.CurrencyPoundIcon,
      classes: "inline h-6 w-6 text-lime-500",
    },
    Invoices: {
      icon: Icon.PresentationChartBarIcon,
      classes: "inline h-6 w-6 text-green-600",
    },
    Jobs: {
      icon: Icon.ViewColumnsIcon,
      classes: "inline h-6 w-6 text-pink-500",
    },
    Tickets: {
      icon: Icon.ChatBubbleLeftEllipsisIcon,
      classes: "inline h-6 w-6 text-yellow-300",
    },
    Staff: { icon: Icon.UserGroupIcon, classes: "inline h-6 w-6 text-red-500" },
    Tracker: {
      icon: Icon.MapPinIcon,
      classes: "inline h-6 w-6 text-indigo-500",
    },
    Vehicles: {
      icon: Icon.TruckIcon,
      classes: "inline h-6 w-6 text-emerald-500",
    },
    default: { icon: Icon.QuestionMarkCircleIcon, classes: "inline h-6 w-6" },
  };
  return iconMap[name] || iconMap["default"];
}
</script>

<template>
  <aside class="flex justify-between items-center w-full md:block">
    <router-link to="/calendar" class="block content-center"><img src="/images/logo.png" alt="Medic 1"
        class="hidden md:block mb-6 xl:w-full xl:aspect-auto xl:visible xl:mb-0" /></router-link>
    <div class="h-2"></div>
    <template v-for="app in apps" :key="app.link">
      <router-link v-if="!app.link.startsWith('http')" :to="app.link"
        class="md:block md:p-3 text-grey-900 hover:bg-slate-100 dark:hover:bg-slate-700 whitespace-nowrap"
        :title="app.name">
        <!-- Dynamically render icons and classes -->
        <component :is="getIconComponent(app.name).icon" :class="getIconComponent(app.name).classes" />
        <span class="ml-2 hidden xl:inline-block">{{ app.name }}</span>
      </router-link>

      <a v-else :href="app.link"
        class="md:block p-3 text-grey-900 hover:bg-slate-100 dark:hover:bg-slate-700 whitespace-nowrap"
        :title="app.name" target="_blank" rel="noopener noreferrer">
        <component :is="getIconComponent(app.name).icon" :class="getIconComponent(app.name).classes" />
        <span class="ml-2 hidden xl:inline-block">{{ app.name }}</span>
      </a>
    </template>
  </aside>
</template>
