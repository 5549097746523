import apiClient from '@/apiClient.js'
const fetchJobs = () => {
  apiClient.get('/api/reference').then(response => {
    return response.data
  })
}

const fetchJob = id => {
  apiClient.get(`/api/reference/$(id)`).then(response => {
    return response.data
  })
}

export default {
  fetchJobs,
  fetchJob,
}
