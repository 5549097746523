<script setup>
import { onMounted, ref } from "vue"
import { fetchJobs } from "@/services/JobService"

onMounted(async () => {
  try {
    const jobs = await fetchJobs();
  } catch (error) {
    console.log(error)
  }
});
</script>
<template>
  <div>
  </div>
</template>
