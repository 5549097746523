import apiClient from "@/apiClient.js";

const uploadFile = async (staffId, label, file) => {
  if (!file) {
    throw new Error("File is required for upload.");
  }

  const formData = new FormData();
  formData.append("file", file);

  return apiClient.post(`/api/staff/${staffId}/upload-file/${label}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export default {
  uploadFile,
};

