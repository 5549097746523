<script setup>
import apiClient from '@/apiClient.js'
import { defineProps, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import { useAuthStore } from '@/stores/AuthStore'

const props = defineProps({
  reference_id: { type: Number, required: true },
})
const auth = useAuthStore()
const histories = ref({})

onMounted(() => {
  getHistories()
})

const getHistories = () => {
  apiClient
    .get('/api/history/' + props.reference_id)
    .then(r => {
      histories.value = r.data
    })
    .catch(e => { })
}
</script>
<template>
  <form-box header="History">
    <div v-for="history in histories" :key="history.id" class="py-2 mb-4">
      <div class="flex gap-4">
        <div class="flex gap-2">
          <div
            class="flex items-center text-xs justify-center w-6 h-6 rounded-full bg-indigo-500 text-white font-bold ">
            {{ history?.user?.name[0] ?? "?" }}
          </div>
          <div>{{ history?.user?.name ?? 'Neptune System' }}</div>
        </div>
        <div class="text-gray-400" :title="dayjs(history.created_at).format('DD/MM/YYYY hh:mm:ss')">
          {{ dayjs(history.created_at).fromNow() }}
        </div>
      </div>
      <div>{{ history.content }}</div>
    </div>
  </form-box>
</template>
