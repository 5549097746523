<script setup>
import { defineEmits, defineProps, ref } from 'vue'

const emits = defineEmits(['update'])

const props = defineProps({
  data: { type: [String, null], required: true },
  editable: { type: Boolean, required: false, default: false },
  placeholder: { type: String, required: false, default: "" },
  type: { type: String, required: false, default: "text" },
})

const displayValue = ref(props.data)

const update = () => emits('update', displayValue.value)
</script>
<template>
  <span v-if="!props.editable">{{ displayValue ?? " &nbsp; " }}</span>
  <template v-else>
    <input v-if="props.type == 'text'" :placeholder="props.placeholder" type="text" v-model="displayValue"
      @input="update" class="w-full" />
    <textarea v-else :placeholder="props.placeholder" type="text" v-model="displayValue" @input="update"
      class="w-full min-h-36" />
  </template>
</template>
