<script setup>
import apiClient from '@/apiClient.js'
import { defineProps, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import { useAuthStore } from '@/stores/AuthStore'

const auth = useAuthStore()
const comments = ref({})
const newComment = ref('')
const addingComment = ref(false)

const editingCommentId = ref(null)

const props = defineProps({
  reference_id: { type: Number, required: true },
})

onMounted(() => {
  getComments()
})

const getComments = () => {
  apiClient
    .get('/api/comments/' + props.reference_id)
    .then(r => {
      comments.value = r.data
    })
    .catch(e => { })
}

const saveComment = comment => {
  addingComment.value = false
  apiClient
    .post('/api/comments/' + props.reference_id, {
      reference_id: props.reference_id,
      message: newComment.value,
    })
    .then(r => {
      getComments()
      newComment.value = ''
    })
    .catch(e => { })
}

const saveEditedComment = () => {
  const comment = comments.value.find(
    comment => comment.id === editingCommentId.value,
  )

  apiClient
    .patch('/api/comments/' + props.reference_id + '/' + comment.id, {
      reference_id: props.reference_id,
      message: comment.message,
    })
    .then(r => {
      getComments()
      newComment.value = ''
      addingComment.value = false
    })
    .catch(e => { })

  toggleEditMode()
}

const deleteComment = comment => {
  if (!confirm('Are you sure you want to delete this comment?')) {
    return
  }
  apiClient
    .delete('/api/comments/' + props.reference_id + '/' + comment.id)
    .then(r => {
      getComments()
    })
    .catch(e => { })
}

const toggleEditMode = commentId => {
  editingCommentId.value =
    editingCommentId.value === commentId ? null : commentId
}

</script>
<template>
  <form-box header="Comments">
    <div v-for="comment in comments" :key="comment.id" class="py-2 mb-4">
      <div class="flex gap-4 mb-2">
        <div class="flex gap-2">
          <div
            class="flex items-center text-xs justify-center w-6 h-6 rounded-full bg-indigo-500 text-white font-bold ">
            {{ comment.user.name[0] ?? "?" }}
          </div>
          <div>{{ comment.user.name }}</div>
        </div>
        <span class="text-gray-400" :title="dayjs(comment.created_at).format('DD/MM/YYYY hh:mm:ss')">
          {{ dayjs(comment.created_at).fromNow() }}
        </span>
        <div class="ml-auto flex gap-4" v-if="
          dayjs(comment.created_at).isAfter(dayjs().subtract(1, 'hour')) &&
          comment.user_id == auth.user.id
        ">
          <button @click.prevent="deleteComment(comment)">❌</button>
          <button @click.prevent="toggleEditMode(comment.id)">✏️</button>
        </div>
      </div>
      <div v-if="editingCommentId === comment.id">
        <textarea class="w-full min-h-32 block mb-2" v-model="comment.message" @keyup.meta.enter="saveEditedComment"
          @keyup.ctrl.enter="saveEditedComment"></textarea>
        <SaveButton @save="saveEditedComment" />
      </div>
      <div class="whitespace-pre-wrap" v-else>
        {{ comment.message }}
      </div>
    </div>
    <button class="btn" @click.prevent="addingComment = true" v-if="!addingComment">
      + Comment
    </button>
    <template v-else>
      <textarea class="w-full min-h-32 block mb-2" v-model="newComment" @keyup.meta.enter="saveComment"
        @keyup.ctrl.enter="saveComment" />
      <SaveButton @save="saveComment" />
    </template>
  </form-box>
</template>
